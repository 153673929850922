import { styled } from 'styled-components'

import _Icon from '~/components/icon'
import _TextInput from '~/components/text-input'

export const Input = styled(_TextInput)`
  position: relative;

  & input {
    padding-left: 44px;
  }

  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none;
    height: 0;
    width: 0;
    margin: 0;
  }
`

export const LockIcon = styled(_Icon)`
  position: absolute;
  bottom: 17px;
  left: 16px;
`

export const ToggleShowPassword = styled(_Icon)`
  cursor: pointer;
  position: absolute;
  bottom: 18px;
  right: 16px;
`
