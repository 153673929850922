import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const LabelWrapper = styled.label`
  display: flex;
  gap: 8px;
`

export const InputWrapper = styled.div<{ $clearable: boolean }>`
  display: grid;
  column-gap: 8px;
  grid-template-columns: ${({ $clearable }) =>
    $clearable ? '1fr 1fr 2fr 0.5fr' : '1fr 1fr 2fr'};
`
