import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { AppState } from '~/reducers'

export default () => {
  const navigate = useNavigate()
  const navigationState = useSelector((state: AppState) => state.navigation)

  useEffect(() => {
    switch (navigationState.action) {
      case 'PUSH':
        navigate(navigationState.url)
        return

      case 'REPLACE':
        navigate(navigationState.url, {
          replace: true,
        })
        return

      case 'BACK':
        navigate(-1)
        return

      default: // do nothing
    }
  }, [navigationState])

  return null
}
