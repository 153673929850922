import React from 'react'

import ContentBlock from '~/components/content-block'
import DoubleBounceSpinner from '~/components/double-bounce-spinner'
import Modal from '~/components/modal'
import { i18n } from '~/i18n'

import type { State as SessionState } from '~/reducers/session'

const Content = ({ expiresIn }: { expiresIn: number }) => {
  return (
    <ContentBlock>
      {i18n.t('components.expiration_warning.content', {
        count: expiresIn,
      })}
    </ContentBlock>
  )
}

type Props = {
  expiresIn: number
  extendExpiry: () => void
  session: SessionState
  show: boolean
}

const ExpirationWarning = (props: Props) => {
  if (props.show) {
    return (
      <Modal
        title={i18n.t('components.expiration.title')}
        actions={{
          primary: {
            onClick: props.extendExpiry,
            className: 'button-confirm',
            text: i18n.t('components.expiration_warning.extend_btn'),
            type: 'progress',
          },
        }}
        size="small"
      >
        {props.session.updating ? (
          <DoubleBounceSpinner />
        ) : (
          <Content expiresIn={props.expiresIn} />
        )}
      </Modal>
    )
  }

  return null
}

export default ExpirationWarning
