import React, { ReactNode, useEffect, useState } from 'react'

import { LOCALE } from '~/config'
import { useTranslationPack } from '~/hooks/translation-pack'
import { i18n } from '~/i18n'

type Props = {
  children: ReactNode
}

function APITranslationsProvider({ children }: Props) {
  const [hasSetTranslations, setHasSetTranslations] = useState(false)
  const { data: translationPack } = useTranslationPack()

  useEffect(() => {
    if (translationPack) {
      i18n.store({ [LOCALE]: translationPack })
      setHasSetTranslations(true)
    }
  }, [translationPack])

  return hasSetTranslations ? children : null
}

export default function TranslationsProvider(props: Props) {
  return <APITranslationsProvider {...props} />
}
