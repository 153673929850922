import classNames from 'classnames'
import React from 'react'

import { TextInputSublabel } from './text-input-sublabel.styles'

import type { ReactNode } from 'react'

export type Props = {
  children?: ReactNode
  className?: string
}

export default ({ children, className }: Props) => {
  if (!children) return null

  return (
    <TextInputSublabel
      className={classNames('amp-text-input-sublabel', className)}
    >
      {children}
    </TextInputSublabel>
  )
}
