import { addFlashMessage } from '~/actions/flash-message'
import { i18n } from '~/i18n'

import {
  getResortFavorites,
  putResortFavorites,
} from '../utils/api/resort-favorites'

import type { Resort, ResortFavoritesData } from '~/types'

type Dispatch = (...args: Array<any>) => any

export const LOAD_RESORT_FAVORITES_START = 'resortFavorites/LOAD_START'
export const LOAD_RESORT_FAVORITES_SUCCESS = 'resortFavorites/LOAD_SUCCESS'
export const LOAD_RESORT_FAVORITES_FAIL = 'resortFavorites/LOAD_FAIL'

export const UPDATE_RESORT_FAVORITES_START = 'resortFavorites/UPDATE_START'
export const UPDATE_RESORT_FAVORITES_SUCCESS = 'resortFavorites/UPDATE_SUCCESS'
export const UPDATE_RESORT_FAVORITES_FAIL = 'resortFavorites/UPDATE_FAIL'

export type ResortFavoritesActions =
  | {
      type: 'resortFavorites/LOAD_START'
    }
  | {
      type: 'resortFavorites/LOAD_SUCCESS'
      data: ResortFavoritesData
    }
  | {
      type: 'resortFavorites/LOAD_FAIL'
    }
  | {
      type: 'resortFavorites/UPDATE_START'
    }
  | {
      type: 'resortFavorites/UPDATE_SUCCESS'
      data: ResortFavoritesData
    }
  | {
      type: 'resortFavorites/UPDATE_FAIL'
    }

export function loadResortFavorites({
  reload = false,
}: {
  reload?: boolean
} = {}) {
  return async (dispatch: Dispatch, getState: (...args: Array<any>) => any) => {
    const {
      resortFavorites: { loaded },
    } = getState()

    if (loaded && !reload) return

    dispatch({
      type: LOAD_RESORT_FAVORITES_START,
    })

    try {
      const jsonResp = await getResortFavorites()
      const data = jsonResp.data.reduce(
        (previousValue, currentResort: Resort) => {
          return { ...previousValue, [currentResort.id]: true }
        },
        {},
      )
      dispatch({
        type: LOAD_RESORT_FAVORITES_SUCCESS,
        data: data,
      })
    } catch {
      dispatch(
        addFlashMessage(
          'error',
          i18n.t('components.flash_messages.resort_favorites_index_error'),
        ),
      )
      dispatch({
        type: LOAD_RESORT_FAVORITES_FAIL,
      })
    }
  }
}

export function updateResortFavorites(data: ResortFavoritesData) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_RESORT_FAVORITES_START,
    })

    const resortIDs = Object.keys(data).filter((resortID) => {
      return data[resortID]
    })

    try {
      await putResortFavorites(resortIDs.map(Number))
      dispatch({
        type: UPDATE_RESORT_FAVORITES_SUCCESS,
        data: data,
      })
    } catch {
      dispatch(
        addFlashMessage(
          'error',
          i18n.t('components.flash_messages.resort_favorites_update_error'),
        ),
      )
      dispatch({
        type: UPDATE_RESORT_FAVORITES_FAIL,
      })
    }
  }
}
