/**
 * Component for showing icons. Available icons are determined by the icon font.
 */
import classNames from 'classnames'
import React, { Component } from 'react'

import { ACTION_KEYCODES } from '~/config'
import AlterraIconUrl from '~/graphics/alterra-white.svg'
import './index.scss'

import type { Glyphs } from './types'

export type Props = {
  className?: string
  glyph: Glyphs
  onBlur?: (event: React.FocusEvent) => void
  onClick?: () => void
  onFocus?: (event: React.FocusEvent) => void
  title?: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  alt?: string
}

const noop = () => {}

class Icon extends Component<Props> {
  handleKeyDown = (e: { keyCode: number; preventDefault: () => void }) => {
    const { onClick = noop } = this.props

    if (ACTION_KEYCODES.includes(e.keyCode)) {
      e.preventDefault()
      onClick()
    }
  }

  extraProps() {
    const { className, glyph, ...extraProps } = this.props
    const otherProps = { ...extraProps }

    if (extraProps.onClick) {
      Object.assign(otherProps, {
        tabIndex: '0',
        onKeyDown: this.handleKeyDown,
      })
    }

    return otherProps
  }

  render() {
    const { className, glyph } = this.props

    switch (glyph) {
      case 'alterra':
        return (
          <i className={className}>
            <img src={AlterraIconUrl} alt="Alterra logo" />
          </i>
        )
    }

    return (
      <i
        className={classNames('amp-icon', `icon-${glyph}`, className)}
        data-testid={`icon-${glyph}`}
        role="img"
        aria-label={`icon-${glyph}`}
        {...this.extraProps()}
      />
    )
  }
}

export default Icon
