import { camelizeKeys } from 'humps'

import type {
  GuestProfile,
  IdentityProviderType,
  API$Profile,
  MeasurementSystem,
} from '~/types'

export type IdentityProviderParams = {
  first_name?: string
  last_name?: string
  email?: string
  registration_token: string
  identity_provider_type?: IdentityProviderType
}

export type ApiGuest = {
  id: string
  profile: API$Profile
  confirmed_at: string
  has_password: boolean
  identity_provider_type?: IdentityProviderType
  identity_providers: Record<string, string>
  age_restricted: boolean
  measurement_system: MeasurementSystem
}

export function socialProfileDeserializer(
  query: IdentityProviderParams,
): Record<string, any> {
  const {
    first_name: firstName,
    last_name: lastName,
    email,
    registration_token: registrationToken,
    identity_provider_type: identityProviderType,
  } = query

  const result: Record<string, any> = {
    firstName: firstName || '',
    lastName: lastName || '',
    email: email || '',
    identityProviderType,
    registrationToken,
  }
  return result
}

/**
 * profileDeserializer extracts the information we are interested in from the
 * profiles returned from the server.
 * @param  {Object} body Parsed json reponse from the server.
 * @return {Object}      Restructured object with data we require.
 */
const editableFieldTranslation = {
  first_name: 'firstName',
  last_name: 'lastName',
  email: 'email',
  dob: 'dob',
  gender: 'gender',
  cloudinary_photo: 'cloudinary',
}

export function profileV2Deserializer(data: API$Profile) {
  const {
    id,
    first_name: firstName = '',
    last_name: lastName = '',
    dob = '',
    gender,
    contact_information: { email = '' } = {},
    cloudinary_photo: {
      uploaded_at: uploadedAt,
      public_id: publicId,
      width,
      height,
      version,
      faces,
    } = {},
    is_anonymous: isAnonymous,
    media = [],
    writable_attributes: writableAttributes = [],
    items: products = [],
    age_category: ageCategory,
    effective_age: effectiveAge,
    addresses,
  } = data

  const editableFields = writableAttributes.map(
    (attribute) => editableFieldTranslation[attribute],
  )
  return {
    id,
    firstName,
    lastName,
    dob,
    gender,
    email,
    addresses: camelizeKeys(addresses),
    isAnonymous,
    media,
    products,
    cloudinary: {
      publicId,
      version,
      width,
      height,
      faces,
      uploadedAt,
    },
    editableFields,
    ageCategory,
    effectiveAge,
  }
}

export function guestProfileDeserializer(data: ApiGuest): GuestProfile {
  const {
    id: guestId,
    confirmed_at: confirmedAt,
    identity_provider_type: identityProviderType = '',
    identity_providers: identityProviders = {},
    has_password: hasPassword,
    age_restricted: ageRestricted,
    measurement_system: measurementSystem,
    profile: {
      id,
      first_name: firstName,
      last_name: lastName,
      dob,
      gender,
      addresses = [],
      contact_information: { email } = {},
      cloudinary_photo: cloudinaryPhoto = {},
    } = {},
  } = data

  const addressDefault = {
    street1: '',
    city: '',
    stateProvince: '',
    country: '',
    zipPostal: '',
  }

  return {
    id,
    guestId,
    firstName,
    lastName,
    dob,
    gender,
    email,
    addresses: camelizeKeys(addresses).map((address) => ({
      ...addressDefault,
      ...address,
    })),
    confirmedAt,
    hasPassword,
    identityProviderType,
    identityProviders,
    cloudinary: camelizeKeys(cloudinaryPhoto),
    ageRestricted,
    measurementSystem,
  }
}
