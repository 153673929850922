import { camelize, camelizeKeys } from 'humps'

import type { CoverageOption } from '~/types'

export const ACTION_KEYCODES = [
  13, // Enter
  32, // Space
]
export const DATE_FORMAT_MEDIUM = 'MMM D, YYYY'
export const DESKTOP_WIDTH = 1024
export const CONTACT_EMAIL = 'info@ikonpass.com'
export const PASSWORD_REGEX = /.{8,}/
export const MY_ACCOUNT_PATH = '/myaccount'
export const AFFIRM_MINIMUM_PAYMENT = 5000
export const SPECIAL_CHARACTERS_REGEX =
  /[^0-9A-Za-zÁáÉéÍíÓóÚúÜüÑñÀàÂâÄäÈèÉéÊêËëÎîÏïÔôŒœÙùÛûÜüŸÿÇç.'’ -]/

export const RESORT_NAMES = {
  SB: 'Steamboat Resort',
  WP: 'Winter Park Resort',
}

export const PROMOTION_ICONS = {
  'college-discount': 'graduation-cap',
  'kids-ski-free': 'child',
  'military-discount': 'angle-double-up',
  '49-down': 'usd',
}

export const RESORT_INFO = {
  WP: {
    name: 'Winter park resort',
  },
  SB: {
    name: 'Steamboat resort',
  },
  CM: {
    name: 'Copper Mountain',
  },
}

export const DEFAULT_LOCALE = 'en'

// Defaults
const constants = window.CONSTANTS || {
  env: null,
  accountUrl: null,
  affirmHost: '',
  affirmCdnHost: '',
  affirmPublicApiKey: '',
  availableLocales: ['us', 'fr'],
  cloudinaryApiUrl: '',
  cloudinaryImageUrl: '',
  countriesAndStates: {
    sortedCountryNames: [],
    countriesWithStates: {},
  },
  defaultCountry: 'United States',
  email_confirmation_status: null,
  emailOauthUrl: null,
  externalRedirectURIs: [],
  facebookOauthUrl: '',
  fraudCheckClientId: '',
  fraudCheckEnvironment: '',
  freedomPayHpcUrl: '',
  gr4vyId: '',
  gr4vyEnvironment: 'sandbox',
  languagePack: null,
  locale: DEFAULT_LOCALE,
  loginFbUrl: '',
  maxReservationDate: '2022-06-30',
  mediaMaxPrintDateForDisplay: '2019-01-01',
  mediaMinPrintDateForDisplay: '2020-01-01',
  phoneNumberDialingCodeRegEx: '',
  phoneNumberValidDialingCodes: [],
  preferences: {
    abilityOptions: [],
    heightOptions: [],
    shoeSizeOptions: [],
    shoeSizeUnitOptions: [],
    weightOptions: [],
  },
  recaptchaSiteKey: null,
  reloadCartOpenUrl: null,
  reloadHost: null,
  reservationLimitPerResort: 10,
  reservationExpiryTime: 15 * 60 * 1000, // 15 minutes
  resortAuthorizationCategory: {
    id: null,
    content: '',
  },
  sessionCookieName: 'session',
  sheerIdMedicalProgramId: null,
  sheerIdMilitaryProgramId: null,
  sheerIdStudentProgramId: null,
  shopRenewUrl: '',
  showImpersonationSessionBanner: false,
  xsrfCookieName: 'XSRF-TOKEN',
  foundationDonationStateWhitelist: [],
  classActionVoucherProductCodes: [],
  zipPostalRegexByCountryCode: {},
}

export const ENV = constants.env
export const ACCOUNT_URL = constants.accountUrl
export const AFFIRM_HOST = constants.affirmHost
export const AFFIRM_CDN_HOST = constants.affirmCdnHost
export const AFFIRM_PUBLIC_API_KEY_BLUE = constants.affirmPublicApiKeyBlue
export const AFFIRM_PUBLIC_API_KEY_DEFAULT = constants.affirmPublicApiKeyDefault
export const AVAILABLE_LOCALES = constants.availableLocales
export const CAPTURE_EMAIL_ADDRESS_AGE = 13
export const CLOUDINARY_API_URL = constants.cloudinaryApiUrl
export const CLOUDINARY_IMAGE_URL = constants.cloudinaryImageUrl
export const CONTACT_NUMBER = '+1 888-682-7669'
export const COUNTRIES_AND_STATES = constants.countriesAndStates
export const COUNTRY_NAMES_TO_COUNTRY_CODES =
  constants.countryNamesToCountryCodes
export const DD_ENV = constants.ddEnv
export const DD_RUM_APPLICATION_ID = constants.ddRumApplicationId
export const DD_RUM_CLIENT_TOKEN = constants.ddRumClientToken
export const DD_RUM_SESSION_REPLAY_SAMPLE_RATE =
  constants.ddRumSessionReplaySampleRate
export const DD_RUM_SESSION_SAMPLE_RATE = constants.ddRumSessionSampleRate
export const DD_SERVICE = constants.ddService
export const DD_SITE = constants.ddSite
export const DD_VERSION = constants.ddVersion
export const EMAIL_CONFIRMATION_STATUS = constants.email_confirmation_status
export const EXTERNAL_REDIRECT_URIS = constants.externalRedirectURIs
export const FACEBOOK_OAUTH_URL = constants.facebookOauthUrl
export const FACEBOOK_SIGNUP_URL = `&${FACEBOOK_OAUTH_URL}&state=signup&utm_nooverride=1`
export const FRAUD_CHECK_CLIENT_ID = constants.fraudCheckClientId
export const FRAUD_CHECK_ENVIRONMENT = constants.fraudCheckEnvironment
export const FREEDOMPAY_HPC_URL = constants.freedomPayHpcUrl
export const GR4VY_ID = constants.gr4vyId
export const GR4VY_ENVIRONMENT = constants.gr4vyEnvironment
export const ID_NON_PARTICIPANT = 'other'
export const LOCALE = constants.locale || DEFAULT_LOCALE
export const LOGIN_FB_URL = constants.loginFbUrl
export const MAX_RESERVATION_DATE = constants.maxReservationDate
export const MEDIA_MAX_PRINT_DATE_FOR_DISPLAY =
  constants.mediaMaxPrintDateForDisplay
export const MEDIA_MIN_PRINT_DATE_FOR_DISPLAY =
  constants.mediaMinPrintDateForDisplay
export const ONBOARDING_GUEST_SOURCE = 'Central-RTP'
export const PHONE_NUMBER_DIALING_CODE_REGEX =
  constants.phoneNumberDialingCodeRegEx
export const PHONE_NUMBER_VALID_DIALING_CODES =
  constants.phoneNumberValidDialingCodes
export const PURCHASABLE_CURRENCIES = ['USD' as const, 'CAD' as const]
export const PREFERENCES_ABILITY_OPTIONS = constants.preferences?.abilityOptions
export const PREFERENCES_HEIGHT_OPTIONS = constants.preferences?.heightOptions
export const PREFERENCES_MEASUREMENT_SYSTEM_OPTIONS = [
  'standard' as const,
  'metric' as const,
]
export const PREFERENCES_SHOE_SIZE_OPTIONS = camelizeKeys(
  constants.preferences?.shoeSizeOptions,
)
export const PREFERENCES_SHOE_SIZE_UNIT_OPTIONS =
  constants.preferences?.shoeSizeUnitOptions?.map(camelize)
export const PREFERENCES_WEIGHT_OPTIONS = constants.preferences?.weightOptions
export const RECAPTCHA_SITE_KEY = constants.recaptchaSiteKey
export const RESERVATION_LIMIT_PER_RESORT = constants.reservationLimitPerResort
export const RESERVATION_EXPIRY_TIME = constants.reservationExpiryTime
export const RESERVATION_WARNING_TIME = 2 * 60 * 1000 // 2 minutes
export const RESORT_AUTHORIZATION_CATEGORY =
  constants.resortAuthorizationCategory
export const SESSION_COOKIE_NAME = constants.sessionCookieName
export const SHOP_GUEST_SOURCE = 'shop'
export const VOUCHER_CLAIM_GUEST_SOURCE = 'voucher_claim'
export const SHOP_RENEW_URL = constants.shopRenewUrl
export const SHEER_ID_MEDICAL_PROGRAM_ID = constants.sheerIdMedicalProgramId
export const SHEER_ID_MILITARY_PROGRAM_ID = constants.sheerIdMilitaryProgramId
export const SHEER_ID_STUDENT_PROGRAM_ID = constants.sheerIdStudentProgramId
export const TRANSLATION_PACK_PATH = constants.translationPackPath
export const IS_GUEST_IMPERSONATION_SESSION =
  constants.isGuestImpersonationSession
export const FOUNDATION_DONATION_STATE_WHITELIST =
  constants.foundationDonationStateWhitelist
export const XSRF_COOKIE_NAME = constants.xsrfCookieName
export const CLASS_ACTION_VOUCHER_PRODUCT_CODES =
  constants.classActionVoucherProductCodes
export const ZIP_POSTAL_REGEX_BY_COUNTRY_CODE =
  constants.zipPostalRegexByCountryCode

const DEFAULT_COUNTRY = constants.defaultCountry

// June 5th, 2020
export const deferralGracePeriodEndDate = new Date(2020, 5, 5)

export const DEFAULT_ADDRESS = {
  type: 'billing' as const,
  street1: '',
  city: '',
  stateProvince: '',
  country: DEFAULT_COUNTRY,
  zipPostal: '',
  phoneNumber: '',
}

export const SAME_ADDRESS_FIELDS = [
  'street1',
  'city',
  'stateProvince',
  'country',
  'zipPostal',
  'phoneNumber',
]

export const EMPTY_CREDIT_CARD = {
  nameOnCard: '',
  number: '',
  month: '',
  year: '',
  cvc: '',
}

// Paths which can be accessed when unconfirmed
export const UNCONFIRMED_PATHS = [
  /register\/confirm$/,
  /assign-passes$/,
  /waiver$/,
  /payment$/,
  /payment\/review$/,
  /myaccount\/orders\//,
]

export const DEFAULT_COVERAGE_OPTION: CoverageOption = 'all_destinations'

export const DEFAULT_MEDIA_PRINT_DATE = '2000-01-01T00:00:00'

export const PRICING_WINDOW_STATUSES = {
  PAST: 'past',
  CURRENT: 'current',
  FUTURE: 'future',
}

// initialize AMP namespace
window.AMP = window.AMP || {}
