import styled from 'styled-components'

import { font } from '~/styles/utils'

export const TextInputLabel = styled.div<{
  $disabled?: boolean
  $hasError?: boolean
}>`
  ${font('heading')}
  font-size: 14px;
  text-transform: uppercase;

  color: ${({ $disabled, $hasError, theme }) =>
    $hasError
      ? theme.color.text.danger
      : $disabled
        ? theme.color.text.disabled
        : theme.color.text.default};
`
