import styled from 'styled-components'

import _Icon from '~/components/icon'
import { font } from '~/styles/utils'

export const Container = styled.label`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Icon = styled(_Icon)<{ $disabled?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  pointer-events: none;
  color: ${({ $disabled, theme }) =>
    $disabled ? theme.color.icon.disabled : theme.color.icon.default};
`

export const Select = styled.select<{
  $disabled?: boolean
}>`
  ${font('heading')};
  text-transform: uppercase;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  margin: 0;
  border: 0;
  padding: 16px;
  color: ${({ $disabled, theme }) =>
    $disabled ? theme.color.text.disabled : theme.color.text.default};
  font-size: 16px;
  line-height: 16px;
  appearance: none;
  padding-right: 42px;
  text-overflow: ellipsis;
  background: none;

  &:focus {
    outline: 2px solid ${({ theme }) => theme.color.border.focused.default};
  }
`

export const Wrapper = styled.div<{
  $disabled?: boolean
  $fullWidth?: boolean
  $hasError?: boolean
  $variant: string
}>`
  box-sizing: border-box;
  position: relative;
  margin: 0;
  padding: 0;
  background: ${({ $disabled, $variant, theme }) =>
    $disabled
      ? theme.color.background.disabled
      : $variant === 'secondary'
        ? theme.color.background.input.secondary
        : theme.color.background.input.default};
  border: 1px solid
    ${({ $disabled, $hasError, theme }) =>
      $disabled
        ? theme.color.border.disabled
        : $hasError
          ? theme.color.border.danger
          : theme.color.border.input.default};
  ${({ $fullWidth }) =>
    $fullWidth ? `width: 100%;` : `min-width: 240px; max-width: 588px;`}
`
