import cloudinaryUpload from '~/utils/cloudinary'

import { loadGroup } from './group'
import { loadMountainChecklist } from './mountain-checklist'
import { loadProfile } from './profile'
import cloudinaryDeserializer from '../deserializers/cloudinary'
import { requestSavePhoto } from '../utils/api/profile-photo'
import { reportGroupedError } from '../utils/logger'

import type { CloudinaryPhoto } from '~/types'

export const PREUPLOAD = 'photo-upload/PREUPLOAD' as const
export const STARTED = 'photo-upload/STARTED' as const
export const UPLOAD_FAILED = 'photo-upload/UPLOAD_FAILED' as const
export const COMPLETED = 'photo-upload/COMPLETED' as const
export const RESET = 'photo-upload/RESET' as const
export const SET_PROGRESS = 'photo-upload/SET_PROGRESS' as const
export const SAVING = 'photo-upload/SAVING' as const
export const SAVED = 'photo-upload/SAVED' as const
export const SAVE_FAILED = 'photo-upload/SAVE_FAILED' as const

type Preupload = {
  type: 'photo-upload/PREUPLOAD'
  profileId: string
}

type Started = {
  type: 'photo-upload/STARTED'
}

type UploadFailed = {
  type: 'photo-upload/UPLOAD_FAILED'
  statusMessage: string
}

type Completed = {
  type: 'photo-upload/COMPLETED'
  data: CloudinaryPhoto
}

type Reset = {
  type: 'photo-upload/RESET'
}

type SetProgress = {
  type: 'photo-upload/SET_PROGRESS'
  progress: number
}

type Saving = {
  type: 'photo-upload/SAVING'
}

export type Saved = {
  type: 'photo-upload/SAVED'
  photo: CloudinaryPhoto
}

type SaveFailed = {
  type: 'photo-upload/SAVE_FAILED'
}

export type Action =
  | Preupload
  | Started
  | UploadFailed
  | Completed
  | Reset
  | SetProgress
  | Saving
  | Saved
  | SaveFailed

type Dispatch = (...args: Array<any>) => any

export function prePhotoUpload(profileId: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: PREUPLOAD,
      profileId: profileId,
    })
  }
}

let photoUploadXhr = null
export function uploadPhoto(file: File, profileId: string) {
  return (dispatch: Dispatch) => {
    if (photoUploadXhr) return
    dispatch({
      type: STARTED,
    })
    return new Promise((resolve: (CloudinaryData) => void, reject) => {
      photoUploadXhr = cloudinaryUpload(file, resolve, reject, (progress) => {
        dispatch({
          type: SET_PROGRESS,
          progress,
        })
      })
    })
      .then((data) => {
        dispatch({
          type: COMPLETED,
          data: cloudinaryDeserializer(data),
        })
      })
      .catch((err) => {
        if (!err.statusMessage) {
          reportGroupedError('uploadPhoto', err, {
            profileId,
          })
        }

        const statusMessage = err.statusMessage || ''
        dispatch({
          type: UPLOAD_FAILED,
          statusMessage: statusMessage,
        })
      })
      .then(() => {
        photoUploadXhr = null
      })
  }
}

let saving = false
export function savePhoto(profileId: string) {
  return async (dispatch: Dispatch, getState: () => Record<string, any>) => {
    if (saving) return
    saving = true

    dispatch({
      type: SAVING,
    })

    try {
      const state = getState()
      const photo = state.photoUpload.photo

      await requestSavePhoto(photo, profileId)

      saving = false
      dispatch({
        type: SAVED,
        photo,
      })
      dispatch(
        loadProfile({
          reload: true,
        }),
      )
      dispatch(
        loadGroup({
          reload: true,
        }),
      )
      dispatch(
        loadMountainChecklist({
          reload: true,
        }),
      )
    } catch (err) {
      reportGroupedError('savePhoto', err, {
        profileId,
      })
      dispatch({
        type: SAVE_FAILED,
      })
      saving = false
    }
  }
}

export function cancelPhotoUpload() {
  if (photoUploadXhr) {
    photoUploadXhr.abort()
    photoUploadXhr = null
  }

  return {
    type: RESET,
  }
}
