import uniq from 'lodash/uniq'

import { i18n } from '~/i18n'
import HTTPError from '~/utils/http-error'
import { reportGroupedError } from '~/utils/logger'

import type { ServerErrors } from '~/types'

export const parseErrorsFromHTTPError = (error: HTTPError): ServerErrors =>
  JSON.parse(error.context.text).errors

const DEFAULT_ERROR_MESSAGE_COMPOSITION_OPTIONS = {
  joinWith: '\r\n',
}

const translateApiErrorKey = (key: string) =>
  i18n.t(`components.errors.${key}`, { defaultValue: '' })

type getErrorMessageFromHTTPErrorOptions = {
  pickKeys?: string[]
  joinWith?: string
}

export const getErrorMessageFromHTTPError = (
  error: HTTPError,
  fallbackMessage: string,
  options?: getErrorMessageFromHTTPErrorOptions,
) => {
  try {
    const config = { ...DEFAULT_ERROR_MESSAGE_COMPOSITION_OPTIONS, ...options }
    const parsedErrors = parseErrorsFromHTTPError(error)
    const errorKeys = config?.pickKeys?.length
      ? Object.keys(parsedErrors).filter((key) => config.pickKeys.includes(key))
      : Object.keys(parsedErrors)

    return (
      uniq(errorKeys.map(translateApiErrorKey))
        .filter(Boolean)
        .join(config.joinWith) || fallbackMessage
    )
  } catch {
    reportGroupedError('getErrorMessageFromHTTPError', error)
  }

  return fallbackMessage
}
