import React from 'react'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import Button from '~/components/button'
import Icon from '~/components/icon'
import { transformMarkdownLinkURI } from '~/utils/uri'

import { supportTaskListItemHeadings } from './content-block.utils'

import type { ReactNode } from 'react'
import type { Components } from 'react-markdown'
import type { ButtonType } from '~/components/button'

type Props = {
  buttonType?: ButtonType
  children: string
  className?: string
  inline?: boolean
  componentOverrides?: Components
}

export default function ContentBlock(props: Props) {
  const components = {
    a: ({ href, children }: { href?: string; children: ReactNode }) => (
      <Button
        type={props.buttonType || 'inline'}
        to={href}
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </Button>
    ),
    input: ({ checked }: { checked?: boolean }) => (
      <Icon glyph={checked ? 'success-solid' : 'error-circle'} />
    ),
    p: ({ children }) => {
      if (props.inline) {
        return <>{children} </>
      }

      return <p>{children}</p>
    },
    ...props.componentOverrides,
  }

  return (
    <Markdown
      className={props.className}
      children={props.children}
      components={components}
      urlTransform={transformMarkdownLinkURI}
      remarkPlugins={[supportTaskListItemHeadings, remarkGfm]}
    />
  )
}
