import classNames from 'classnames'
import React, { Component, Fragment } from 'react'

import ContentBlock from '~/components/content-block'
import Footer from '~/components/footer'
import GlobalHeader from '~/components/global-header'
import Modal from '~/components/modal'
import { MY_ACCOUNT_PATH } from '~/config'
import { menuContent } from '~/config/navigation'
import { i18n } from '~/i18n'
import { allItems } from '~/utils/cart'

import CartChanged from './components/cart-changed.component'
import DefaultStructure from './default-structure'
import { Props } from './default.types'
import FlashMessageBanner from '../flash-message-banner'

export default class Default extends Component<Props> {
  static defaultProps = {
    showMenu: true,
    showCurrentGuest: true,
    showShopPasses: true,
  }

  getErrorMessage() {
    return this.props.flashMessages.find(({ type }) => type === 'error')
  }

  getInfoMessage() {
    return this.props.flashMessages.find(({ type }) =>
      ['alert', 'info', 'message'].includes(type),
    )
  }

  renderFlashMessage() {
    const message = this.getInfoMessage()
    if (!message) return
    return (
      <FlashMessageBanner
        message={message}
        onDismiss={this.props.onFlashMessageRemove.bind(null, message)}
      />
    )
  }

  renderHeader() {
    if (this.props.foodBev) return null

    const { cart } = this.props
    // @ts-expect-error TS2345
    const cartItemCount = allItems(cart).length

    let profile

    if (this.props.profile && this.props.profile.firstName) {
      profile = this.props.profile
    }

    const props = {
      cartLink: '/cart',
      homeLink: i18n.t('links.home_link.to'),
      locale: i18n.locale,
      cartItemCount: cartItemCount,
      showLogin: this.props.showLogin,
      content: menuContent(this.props),
      showMenu: !!this.props.showMenu,
      currentGuestProfile: profile,
      myAccountPath: MY_ACCOUNT_PATH,
      currency: cart.payloadCurrency,
    }

    if (!this.props.showCurrentGuest) delete props.currentGuestProfile

    // @ts-expect-error TS2322
    return <GlobalHeader {...props} />
  }

  renderFooter() {
    if (!this.props.hideFooter) {
      return <Footer flat />
    }
  }

  renderErrorMessage() {
    const message = this.getErrorMessage()
    if (!message) return
    return (
      <Modal
        actions={{
          primary: {
            onClick: this.props.onFlashMessageRemove.bind(this, message),
            className: 'button-confirm',
            text: i18n.t('components.errors.retry'),
          },
        }}
        className="error"
        size="small"
      >
        <div>
          <h2>{i18n.t('components.errors.title')}</h2>
          <ContentBlock>{message.text}</ContentBlock>
        </div>
      </Modal>
    )
  }

  render() {
    const className = classNames('amp-layout-default', this.props.className)
    return (
      <DefaultStructure
        className={className}
        header={this.renderHeader()}
        flashMessage={this.renderFlashMessage()}
        footer={this.renderFooter()}
        foodBev={this.props.foodBev}
      >
        <Fragment>
          {this.props.children}
          {this.renderErrorMessage()}
        </Fragment>
        {this.props.showCartChangedMessageIfApplicable &&
          this.props.cart?.changed && <CartChanged />}
      </DefaultStructure>
    )
  }
}
