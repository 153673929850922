export default {
  black: '#000',
  blueCreek: '#007DB7',
  blueCreekDark: '#006695',
  blueIris: '#089ade',
  brightBlue: '#089ADE',
  brightRed: '#fad1d0',
  coolGrey: '#D9D9D9',
  coolGreyLighter: '#F0F0EF',
  darkestGrey: '#1D1F1F',
  darkGrey: '#373A36',
  green: '#278189',
  ikonBlue: '#092340',
  ikonGold: '#ffc72c',
  lighterGrey: '#F4F4F4',
  lightestGrey: '#F8F8F8',
  lightGrey: '#F1F1F1',
  mediumGrey: '#6A6C69',
  red: '#D91208',
  slateGrey: '#9DA7B3',
  textDisabled: '#C2C2C2',
  textSubdued: '#575757',
  white: '#FFF',
}
