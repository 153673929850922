import styled from 'styled-components'

import { font } from '~/styles/utils'

export const Container = styled.label<{
  $fullWidth?: boolean
}>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  ${({ $fullWidth }) => !$fullWidth && `max-width: 588px;`}
`

export const Input = styled.input<{ $disabled?: boolean }>`
  flex-grow: 1;
  font-size: 16px;
  box-sizing: border-box;
  padding: 0 16px;
  height: 48px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  border: none;
  background-color: transparent;
  color: ${({ $disabled, theme }) =>
    $disabled ? theme.color.text.disabled : theme.color.text.default};

  &:focus {
    color: ${({ theme }) => theme.color.text.default};
    outline: 2px solid ${({ theme }) => theme.color.border.input.focused};
  }
`

export const TextArea = styled(Input).attrs({ as: 'textarea' })`
  ${font('base')}
  padding: 16px;
  height: auto;
  min-height: 96px;
`

export const LabelWrapper = styled.div`
  display: flex;
  gap: 8px;
`

export const InputWrapper = styled.div<{
  $disabled?: boolean
  $hasError?: boolean
  $variant?: string
}>`
  align-items: center;
  background-color: ${({ $disabled, $variant, theme }) =>
    $disabled
      ? theme.color.background.disabled
      : $variant === 'secondary'
        ? theme.color.background.input.secondary
        : theme.color.background.input.default};
  border: 1px solid
    ${({ $disabled, $hasError, theme }) =>
      $disabled
        ? theme.color.border.disabled
        : $hasError
          ? theme.color.border.danger
          : theme.color.border.input.default};
  clear: both;
  display: flex;
`
