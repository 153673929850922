import React from 'react'

import Label from '~/components/text-input-label'

import { Container, ReactInternationalPhoneInput } from './phone-input.styles'

import 'react-international-phone/style.css'

import type { ReactNode } from 'react'

export type Props = {
  value: string
  onChange: (arg0: string) => void
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void
  disabled?: boolean
  label?: ReactNode
  hasError?: boolean
  id?: string
  className?: string
  variant?: 'primary' | 'secondary'
}

export default function PhoneInput({
  hasError,
  disabled,
  value,
  onChange,
  onBlur,
  id = 'phone',
  label,
  className,
  variant,
}: Props) {
  return (
    <Container className={className} htmlFor={id}>
      <Label disabled={disabled} hasError={hasError}>
        {label}
      </Label>
      <ReactInternationalPhoneInput
        $hasError={hasError}
        defaultCountry="us"
        disabled={disabled}
        $disabled={disabled}
        value={value}
        onChange={(_, { inputValue }) => onChange(inputValue)}
        onBlur={onBlur}
        inputProps={{ id }}
        $variant={variant}
      />
    </Container>
  )
}
