import ikonWebTokens from '@alterra-mountain-company/alterra-design-system/tokens/ikonWeb'

import border from './borders'
import color from './colors'
import device from './devices'
import font from './fonts'
import layer from './layers'
import variables from './variables'

import type { DefaultTheme } from 'styled-components'

const legacyTheme = {
  border,
  color,
  device,
  font,
  layer,
  variables,
}
const theme: DefaultTheme = {
  ...ikonWebTokens,
  _legacy: legacyTheme,
}

export type Devices = keyof typeof device
export type Colors = keyof typeof color
export type Fonts = keyof typeof font
export type Borders = keyof typeof border
export type Layers = keyof typeof layer
export type Variables = keyof typeof variables
export type LegacyTheme = typeof legacyTheme
export type IkonWebTheme = typeof ikonWebTokens

export type TextAlign =
  | 'center'
  | 'left'
  | 'right'
  | 'justify'
  | 'initial'
  | 'inherit'

export default theme
