import { camelizeKeys } from 'humps'

import { push } from '~/actions/navigation'
import { createGuest } from '~/utils/api/guest'
import HTTPError from '~/utils/http-error'
import { reportGroupedError } from '~/utils/logger'
import { BASE_ERROR_KEY } from '~/utils/validations'

import { profileUpdate } from './profile'

import type { GuestSource } from '../utils/api/guest'
import type { AppState } from '~/reducers'
import type { CreateGuestProfile } from '~/types'

export const SIGNING_UP = 'SIGNING_UP'

export function signUpAsync(
  profileInfo: CreateGuestProfile,
  source: GuestSource,
  redirect?: string,
) {
  return async (dispatch, getState: () => AppState) => {
    const state = getState()
    if (state.ui.createMyAccount.signingUp) return

    dispatch({
      type: SIGNING_UP,
      state: true,
    })

    try {
      const resp = await createGuest(profileInfo, source)

      dispatch({
        type: SIGNING_UP,
        state: false,
      })

      if (resp.ok) {
        dispatch(
          profileUpdate({
            password: null,
          }),
        ) // no longer need password in state

        dispatch(push(redirect || '/login'))
      } else if (resp.status === 422) {
        const { errors } = await resp.json()

        dispatch(
          profileUpdate({
            errors: camelizeKeys(errors),
          }),
        )
      } else {
        throw new HTTPError('Unable to sign up', resp, '')
      }
    } catch (err) {
      dispatch({
        type: SIGNING_UP,
        state: false,
      })
      dispatch(
        profileUpdate({
          errors: {
            [BASE_ERROR_KEY]: 'Unknown',
          },
        }),
      )
      reportGroupedError('signUpAsync', err)
    }
  }
}
