import classNames from 'classnames'
import React, { Component } from 'react'

import Label from '~/components/text-input-label'

import { Container, Icon, Select, Wrapper } from './select-input.styles'

import type { ReactNode } from 'react'
export type OptionObject = {
  value: string | number
  label: string | number
  disabled?: boolean
}
export type Option = string | number | OptionObject

export type Props = {
  disabled?: boolean
  onChange: (...args: Array<any>) => any
  hasError?: boolean
  label?: ReactNode
  name?: string
  value?: string | number
  defaultValue?: string | number
  id?: string
  options: Option[]
  className?: string
  variant?: 'primary' | 'secondary'
  fullWidth?: boolean
}

export default class SelectInput extends Component<Props> {
  selectInput = null

  // Prevent the select input from being initialized with a value that's not
  // selectable in the list. The first item in the list will be selected by default;
  // we should update the value to reflect that.
  componentDidMount() {
    const optionValues = this.optionObjects().map((option) => option.value)

    if (
      optionValues.length > 0 &&
      !optionValues.includes(this.props.value) &&
      !(optionValues[0] === this.props.defaultValue)
    ) {
      this.selectInput?.setAttribute('value', optionValues[0])

      this.selectInput?.dispatchEvent(
        new Event('change', {
          bubbles: true,
        }),
      )
    }
  }

  optionObjects(): OptionObject[] {
    return this.props.options.map((option: Option) => {
      let value
      let label
      let disabled = false

      if (typeof option === 'object') {
        ;({ value, label, disabled } = option)
      } else {
        value = option
        label = option
      }

      return {
        value,
        disabled,
        label,
      }
    })
  }

  renderOption({ value, label, disabled }: OptionObject, index: number) {
    return (
      <option key={index} value={value} disabled={disabled}>
        {label}
      </option>
    )
  }

  renderLabelText() {
    if (!this.props.label) return
    return (
      <Label
        className="label"
        disabled={this.props.disabled}
        hasError={this.props.hasError}
      >
        {this.props.label}
      </Label>
    )
  }

  render() {
    const fieldClass = classNames('amp-select-input', this.props.className)

    return (
      <Container className={fieldClass} htmlFor={this.props.id}>
        {this.renderLabelText()}
        <Wrapper
          className="select-wrapper"
          $hasError={this.props.hasError}
          $fullWidth={this.props.fullWidth}
          $variant={this.props.variant}
          $disabled={this.props.disabled}
        >
          <Select
            ref={(ref) => (this.selectInput = ref)}
            name={this.props.name}
            aria-label={this.props.name}
            id={this.props.id}
            value={this.props.value}
            onChange={this.props.onChange}
            onBlur={this.props.onChange}
            disabled={this.props.disabled}
            $disabled={this.props.disabled}
            defaultValue={this.props.defaultValue}
          >
            {this.optionObjects().map(this.renderOption)}
          </Select>
          <Icon glyph="chevron-down" $disabled={this.props.disabled} />
        </Wrapper>
      </Container>
    )
  }
}
