import styled, { css } from 'styled-components'

import _Icon from '~/components/icon'

export const Container = styled.div<{ $variant?: string }>`
  position: relative;
  color: ${({ theme }) => theme.color.text.danger};

  ${({ $variant }) =>
    $variant === 'block' &&
    css`
      color: ${({ theme }) => theme.color.text.default};
      border: 1px solid ${({ theme }) => theme.color.border.danger};
      padding: 17px 16px 22px 20px;
      background-color: ${({ theme }) => theme.color.background.default};
    `}
`

export const Content = styled.div<{ $variant?: string }>`
  display: block;
  margin-left: 0 !important;

  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }

  ${({ $variant }) =>
    $variant === 'block' &&
    css`
      margin-left: 34px !important;
      text-align: left;
      padding-bottom: 0;
    `}
`

export const Icon = styled(_Icon)`
  position: absolute;
  color: ${({ theme }) => theme.color.icon.danger};
`
