import HTTPError from '~/utils/http-error'

import { authedFetch } from '../fetch'

import type { API$Resort } from '~/types'

const endpoint = '/api/v2/resorts'

type ResortsResponse = {
  data: Array<API$Resort>
  errors: Array<string>
}

export async function requestResorts(): Promise<ResortsResponse> {
  const res = await authedFetch(endpoint)

  if (res.ok) {
    const json = await res.json()
    return json
  }

  throw new HTTPError(`failed: GET ${endpoint}`, res, await res.text())
}
